import { BasePageLayout } from "components/layout/basePageLayout/basePageLayout"

export interface NotFoundPageProps {

}

export const NotFoundPage = (props: NotFoundPageProps) => {
  return (
    <BasePageLayout>
      <div className="column">
        Siden ble ikke funnet.
      </div>
    </BasePageLayout>
  )
}
