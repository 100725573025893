import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
} from "react"
import 'components/calculationTask/createOrEditBuildingModal/geographicVertexInputFieldSet/geographicVertexInputFieldSet.scoped.scss'
import { TextInput } from "components/common/formFields/textInput/textInput"
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import {
  FormikErrors,
  FormikTouched,
  useFormik,
} from "formik"
import * as yup from "yup"
import {
  VertexInputFieldSetHandle,
  VertexInputFieldSetProps,
} from "components/calculationTask/createOrEditBuildingModal/vertexInputTypes"
import { Vertex } from "components/calculationTask/createOrEditBuildingModal/buildingFormDataModel"
import {
  altitudeValidator,
  latitudeValidator,
  longitudeValidator,
} from "utils/validators/coordinateValidators"
import {
  VertexInputFieldsToolbar
} from "components/calculationTask/createOrEditBuildingModal/vertexInputFieldsToolbar/vertexInputFieldsToolbar"
import papaParse from "papaparse"
import clipboard from "clipboardy"
import { toast } from "react-hot-toast"
import { parseGeographicCoordinates } from "components/calculationTask/createOrEditBuildingModal/parseClipboardContent"


interface FormDataModel {
  vertices: Partial<Vertex>[]
}

const initialValues: FormDataModel = {
  vertices: [],
}

const vertexValidationSchema = yup.object()
  .shape({
    latitude: latitudeValidator,
    longitude: longitudeValidator,
    altitude: altitudeValidator,
  })

const formValidationSchema = yup.object()
  .shape({
    vertices: yup.array()
      .of(vertexValidationSchema),
  })


export const GeographicVertexInputFieldSet = forwardRef((props: VertexInputFieldSetProps, ref: Ref<VertexInputFieldSetHandle>) => {
  const form = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: () => Promise.resolve(true),
  })

  useImperativeHandle(ref, () => ({
    getValues: async () => {
      const success: true | undefined = await form.submitForm()

      if (!success) {
        return {
          validationSucceeded: false,
        }
      }

      return {
        validationSucceeded: true,
        vertices: form.values.vertices as Vertex[],
      }
    },
  }))

  useEffect(() => {
    // Initialize the form with at least one row
    if (form.values.vertices.length === 0) {
      form.resetForm({
        values: {
          ...form.values,
          vertices: [getEmptyRow()],
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getEmptyRow = () => {
    const vertex: Partial<Vertex> = {
      altitude: undefined,
      latitude: undefined,
      longitude: undefined,
    }

    return vertex
  }

  const addFormRow = () => {
    form.setFieldValue('vertices', [...form.values.vertices, getEmptyRow()])
  }

  const removeFormRow = (index: number) => {
    const newErrors = form.errors.vertices ? [...form.errors.vertices as string[]] : []
    newErrors.splice(index, 1)
    form.setErrors({
      ...form.errors,
      vertices: newErrors,
    })

    const newTouched = form.touched.vertices ? [...form.touched.vertices] : []
    newTouched.splice(index, 1)
    form.setTouched({
      ...form.touched,
      vertices: newTouched,
    })

    const { vertices } = form.values
    const newVertices = [...vertices]
    newVertices.splice(index, 1)
    form.setFieldValue('vertices', newVertices)
  }

  const getRowTouchedStatus = (index: number) => {
    if (!form.touched.vertices) {
      return undefined
    }

    return form.touched.vertices[index] as FormikTouched<Vertex>
  }

  const getRowErrors = (index: number) => {
    if (!form.errors.vertices) {
      return undefined
    }

    return form.errors.vertices[index] as FormikErrors<Vertex>
  }


  useEffect(() => {
    const validVertices = form.values.vertices
      .filter(v => vertexValidationSchema.isValidSync(v))
    props.onChange(validVertices as Vertex[])
    // Do not update on props changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.vertices])

  const copyToClipboard = async () => {
    const text = papaParse.unparse(form.values.vertices, {
      delimiter: "\t",
      header: false,
    })
    try {
      await clipboard.write(text)
    } catch {
      toast.error("Fikk ikke tilgang til utklippstavlen")
      return
    }
  }

  const onPasteButtonClicked = async () => {
    let text: string
    try {
      text = await clipboard.read()
    } catch {
      toast.error("Fikk ikke tilgang til utklippstavlen. Du kan lime rett inn i en celle i stedet")
      return
    }
    const vertices = parseGeographicCoordinates(text)

    if (vertices === null) {
      toast.error("Fant ingen punkter i utklippstavlen")
      return
    }
    insertVertices(vertices, 0)
  }

  const insertVertices = (vertices: Vertex[], startingRowIndex: number) => {
    const existingItemsBeforeCurrentRow = form.values.vertices.slice(0, startingRowIndex)
    const existingItemsAfterCurrentRow = form.values.vertices.slice(startingRowIndex + vertices.length)
    form.setFieldValue('vertices', [
      ...existingItemsBeforeCurrentRow,
      ...vertices,
      ...existingItemsAfterCurrentRow,
    ])
  }

  const resetForm = () => {
    form.resetForm()
  }

  const deleteAllVertices = () => {
    form.setFieldValue('vertices', [getEmptyRow()])
    form.setTouched({})
  }

  const onPasteInTextInput = (event: React.ClipboardEvent, rowIndex: number) => {
    const clipboardContent = event.clipboardData.getData("text")
    const vertices = parseGeographicCoordinates(clipboardContent)

    if (vertices === null) {
      return
    }

    event.preventDefault()
    insertVertices(vertices, rowIndex)
  }

  return (
    <div className="column gap2">
      <div className="form-label">Hjørnepunkter</div>
      <div className="mb2">
        <VertexInputFieldsToolbar
          onClickCopyToClipboard={copyToClipboard}
          onClickPaste={onPasteButtonClicked}
          onClickResetForm={resetForm}
          canResetForm={form.dirty || form.touched.vertices !== undefined }
          onClickDeleteAll={deleteAllVertices}
          canDeleteAll={form.values.vertices.length > 0} />
      </div>
      <div className="vertex-input-table column">
        <div className="table-head">
          <span className="table-head-cell"></span>
          <span className="table-head-cell">Breddegrad</span>
          <span className="table-head-cell">Lengdegrad</span>
          <span className="table-head-cell">Høyde (m)</span>
        </div>
        {form.values.vertices.map((vertex, index) => (
          <div className="table-row" key={index}>
            <div className="table-cell">
              <span>{index + 1}</span>
            </div>
            <div className="table-cell">
              <TextInput
                name={`vertices[${index}].latitude`}
                value={vertex.latitude ?? ''}
                isInvalid={!!getRowTouchedStatus(index)?.latitude && !!getRowErrors(index)?.latitude}
                errors={getRowErrors(index)?.latitude}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                onPaste={event => onPasteInTextInput(event, index)}
              />
            </div>
            <div className="table-cell">
              <TextInput
                name={`vertices[${index}].longitude`}
                value={vertex.longitude ?? ''}
                isInvalid={!!getRowTouchedStatus(index)?.longitude && !!getRowErrors(index)?.longitude}
                errors={getRowErrors(index)?.longitude}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                onPaste={event => onPasteInTextInput(event, index)}
              />
            </div>
            <div className="table-cell">
              <TextInput
                name={`vertices[${index}].altitude`}
                value={vertex.altitude ?? ''}
                isInvalid={!!getRowTouchedStatus(index)?.altitude && !!getRowErrors(index)?.altitude}
                errors={getRowErrors(index)?.altitude}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                onPaste={event => onPasteInTextInput(event, index)}
              />
            </div>
            <span className="ml4 mr1">
              <ClearButton
                onClick={() => removeFormRow(index)}
                iconName="delete"
                text="Slett"
              />
            </span>
          </div>
        ))}
      </div>
      <div className="row">
        <ClearButton
          iconName={"plus"}
          text="Legg til punkt"
          onClick={addFormRow}
        />
      </div>
    </div>
  )
})

