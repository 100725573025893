import {
  createContext,
  FC,
  useContext,
  useRef,
} from "react"
import MapRenderer from "components/calculationTask/mapRenderer"
import { LatLongPoint } from "components/calculationTask/mapPane/mapPane"

export interface MapService {
  registerMapRenderer: (mapRenderer: MapRenderer) => void
  unRegisterMapRenderer: () => void
  drawCalculationArea: () => void
  addCalculationPoints: () => void
  cancelDrawing: () => void
  goToLocation: (location: LatLongPoint) => void
  getMapCenter: () => LatLongPoint
}

const MapServiceContext = createContext<MapService | undefined>(undefined)

export const MapServiceProvider: FC = ({ children }) => {
  const mapRenderer = useRef<MapRenderer>()

  const registerMapRenderer = (newRenderer: MapRenderer) => {
    mapRenderer.current = newRenderer
  }

  const unRegisterMapRenderer = () => {
    mapRenderer.current = undefined
  }

  const ensureMapRendererRegistered = () => {
    if (!mapRenderer.current) {
      throw new Error("No map renderer registered")
    }
  }

  const drawCalculationArea = () => {
    ensureMapRendererRegistered()
    mapRenderer.current?.drawCalculationArea()
  }

  const addCalculationPoints = () => {
    ensureMapRendererRegistered()
    mapRenderer.current?.addCalculationPoints()
  }

  const cancelDrawing = () => {
    ensureMapRendererRegistered()
    mapRenderer.current?.cancelDrawing()
  }

  const goToLocation = (location: LatLongPoint) => {
    ensureMapRendererRegistered()
    mapRenderer.current?.goTo(location)
  }

    const getMapCenter = () => {
      ensureMapRendererRegistered()
    return mapRenderer.current!.getMapCenter()
  }

  return (
    <MapServiceContext.Provider
      value={{
        registerMapRenderer,
        unRegisterMapRenderer,
        drawCalculationArea,
        addCalculationPoints,
        cancelDrawing,
        goToLocation,
        getMapCenter,
      }}
    >
      {children}
    </MapServiceContext.Provider>
  )
}

export const useMapService = () => {
  const context = useContext<MapService | undefined>(MapServiceContext)
  if (!context) {
    const serviceName = Object.keys({ MapServiceContext })[0]
    throw new Error(serviceName + " was not provided. "
      + "Make sure the component is a child of the required service provider")
  }
  return context
}
