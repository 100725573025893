import * as yup from "yup"
import { GeographicCoordinates } from "services/sicalcApi/sharedEntities/geographicCoordinates"
import { FormikErrors } from "formik"
import { FormDataModel } from "components/calculationTask/calculationTaskSetupView/formDataModel"
import {
  altitudeValidator,
  latitudeValidator,
  longitudeValidator,
} from "utils/validators/coordinateValidators"

export interface Vertex extends GeographicCoordinates {
  altitude: number
}

export interface BuildingFormDataModel {
  name: string
  createFacadePointsAt4m: boolean
  createFacadePointsAt1_5m: boolean
  referenceAltitude: ReferenceAltitude
  geometryType: GeometryType
  vertices: Partial<Vertex>[]
}

export type ReferenceAltitude = "terrain" | "seaLevel"

// For polygons, the last point is connected to the first one.
export type GeometryType =  "polygon" | "polyline"


// The error model with a dummy field. The field itself is added in the
// validation schema below using a magic string
export type CreateOrEditBuildingFormErrors = FormikErrors<FormDataModel & { allVertices: never }>


export const buildingValidationSchema = yup.object()
  .shape({
    name: yup.string()
      .required("Påkrevd")
      .max(70, "Maks 70 tegn"),
    vertices: yup.array()
      .of(yup.object()
        .shape({
          latitude: latitudeValidator,
          longitude: longitudeValidator,
          altitude: altitudeValidator,
        })),
  })
  .test(
    "mustHaveEnoughVertices",
    (value, context) => {
      const { vertices, geometryType } = value
      if (geometryType === "polyline"
        && vertices !== undefined && vertices.length < 2
      ) {
        return context.createError({
          path: "allVertices",
          message: "En skjerm består av minst to punkter",
        })
      }
      if (geometryType === "polygon"
        && vertices !== undefined && vertices.length < 3
      ) {
        return context.createError({
          path: "allVertices",
          message: "En bygning består av minst tre punkter",
        })
      }
      return true
    })
