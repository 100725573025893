import React, {
  FC,
  useCallback,
  useRef,
  useState,
} from "react"
import './createOrEditBuildingModal.scoped.scss'
import { ModalBase } from "components/common/modals/modalBase/modalBase"
import { LightButton } from "components/common/buttons/lightButton/lightButton"
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"
import {
  ImportCalculationPointsModalResult,
} from "components/calculationTask/importCalculationPointsModal/importCalculationPointsModal"
import { ModalProps } from "services/modal/modalService"
import { TextInput } from "components/common/formFields/textInput/textInput"
import { MultiToggle } from "components/common/buttons/multiToggle/multiToggle"
import { CheckboxInput } from "components/common/formFields/CheckboxInput/CheckboxInput"
import { useFormik } from "formik"
import {
  BuildingFormDataModel,
  buildingValidationSchema,
  CreateOrEditBuildingFormErrors,
  GeometryType,
  ReferenceAltitude,
  Vertex,
} from "components/calculationTask/createOrEditBuildingModal/buildingFormDataModel"
import {
  VertexInputFieldSetHandle,
  VertexInputFieldSetProps,
} from "components/calculationTask/createOrEditBuildingModal/vertexInputTypes"
import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"
import {
  CoordinateSystemSelectionField,
} from "components/common/formFields/coordinateSystemSelectionField/coordinateSystemSelectionField"
import { toast } from "react-hot-toast"
import { BuildingPreview } from "components/calculationTask/createOrEditBuildingModal/buildingPreview/buildingPreview"
import {
  GeographicVertexInputFieldSet,
} from "components/calculationTask/createOrEditBuildingModal/geographicVertexInputFieldSet/geographicVertexInputFieldSet"
import {
  UtmVertexInputFieldSet,
} from "components/calculationTask/createOrEditBuildingModal/utmVertexInputFieldSet/utmVertexInputFieldSet"

export interface Building {
  name: string
  createFacadePointsAt4m: boolean
  createFacadePointsAt1_5m: boolean
  referenceAltitude: ReferenceAltitude
  geometryType: GeometryType
  vertices: Vertex[]
}

export interface CreateOrEditBuildingModalProps extends ModalProps {
  initialValue?: Building
  coordinateSystem?: CoordinateSystem
}

export interface CreateOrEditBuildingModalResult {
  success: boolean
  // newBuilding: Building
}


export const CreateOrEditBuildingModal: FC<CreateOrEditBuildingModalProps> = (props) => {
  const vertexInputFieldSetRef = useRef<VertexInputFieldSetHandle>(null)
  // TODO: Get initial coordinate system from props
  const [ coordinateSystem, setCoordinateSystem ] = useState<CoordinateSystem>(CoordinateSystem.Utm)

  const initialValues: BuildingFormDataModel = {
    name: "",
    geometryType: "polygon",
    createFacadePointsAt4m: true,
    createFacadePointsAt1_5m: true,
    referenceAltitude: "terrain",
    vertices: [],
  }

  const form = useFormik({
    initialValues,
    validationSchema: buildingValidationSchema,
    onSubmit: () => Promise.resolve(true),
  })

  const dismiss = () => {
    return props.modalContext.close({ success: false } as ImportCalculationPointsModalResult)
  }

  const submit = async () => {
    // Fetch the values from the nested vertex input field set
    const vertexInputFieldValues = await vertexInputFieldSetRef.current!.getValues()
    if (vertexInputFieldValues.validationSucceeded) {
      await form.setFieldValue('vertices', vertexInputFieldValues.vertices)
    }

    const formIsValid: true | undefined = await form.submitForm()

    if (!formIsValid ||  !vertexInputFieldValues.validationSucceeded) {
      console.debug("Validation failure\nValues:", form.values, "Errors:", form.errors)
      return
    }

    toast.success("Suksess! Inndataene er gyldige, men lagring er ikke implementert ennå")
  }

  const onVerticesChanged = useCallback((validVertices: Vertex[]) => {
    // This needs useCallback to avoid an endless loop in the child components
    form.setFieldValue('vertices', validVertices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buildVertexInputFieldSet = () => {
    const vertexInputFieldSetProps: VertexInputFieldSetProps = {
      onChange: onVerticesChanged,
      initialValue: [],
    }
    if (coordinateSystem === CoordinateSystem.Geographic) {
      return (
        <GeographicVertexInputFieldSet
          ref={vertexInputFieldSetRef}
          {...vertexInputFieldSetProps}
        />
      )
    }
    if (coordinateSystem === CoordinateSystem.Utm) {
      return (
        <UtmVertexInputFieldSet
          ref={vertexInputFieldSetRef}
          {...vertexInputFieldSetProps}
        />
      )
    }
  }

  return (
    <ModalBase
      onDismiss={dismiss}
      dismissOnClickOutside={false}
      heading="Ny bygning eller skjerm"
      actions={
        <>
          <LightButton
            onClick={dismiss}
          >
            Avbryt
          </LightButton>
          <PrimaryButton
            onClick={submit}
          >
            Lagre
          </PrimaryButton>
        </>
      }
    >
      <div className="create-or-edit-building-modal column gap4">
        <label className="column">
          <span className="form-label">Navn</span>
          <TextInput
            name={`name`}
            value={form.values.name}
            isInvalid={!!form.touched.name && !!form.errors.name}
            errors={form.errors.name}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </label>
        <label className="column">
          <span className="form-label">Type objekt</span>
          <MultiToggle
            value={form.values.geometryType}
            options={[
              {
                label: "Bygning",
                value: "polygon",
              },
              {
                label: "Skjerm",
                value: "polyline",
              },
            ]}
            onChange={newValue => form.setFieldValue("geometryType", newValue)}
          />
        </label>
        <div>
          <legend className="form-label">Beregningspunkt på fasade</legend>
          <div className="column gap1">
            <CheckboxInput
              label="1,5 m over terrenget"
              value={form.values.createFacadePointsAt1_5m}
              onChange={newValue => form.setFieldValue('createFacadePointsAt1_5m', newValue)}
            />
            <CheckboxInput
              label="4 m over terrenget"
              value={form.values.createFacadePointsAt4m}
              onChange={newValue => form.setFieldValue('createFacadePointsAt4m', newValue)}
            />
          </div>
        </div>
        <label className="column">
          <span className="form-label">Høydeangivelse</span>
          <MultiToggle
            value={form.values.referenceAltitude}
            options={[
              {
                label: "Relativt til terreng",
                value: "terrain",
              },
              {
                label: "Kotehøyde",
                value: "seaLevel",
              },
            ]}
            onChange={newValue => form.setFieldValue("referenceAltitude", newValue)}
          />
        </label>

        <div className="row">
          <CoordinateSystemSelectionField
            label={"Koordinatsystem"}
            value={coordinateSystem}
            isInvalid={false}
            onChange={(e) => setCoordinateSystem(e.target.value as CoordinateSystem)}
            disabled={false}
          />
        </div>
        <div className="vertices">
          {buildVertexInputFieldSet()}
          {!!form.touched.vertices && !!(form.errors as CreateOrEditBuildingFormErrors).allVertices && (
            <div className="validation-error mt4 row align-center">
              <i className="mdi mdi-alert-circle-outline mr1"></i>
              <p>
                {(form.errors as CreateOrEditBuildingFormErrors).allVertices}
              </p>
            </div>
          )}
        </div>
        <BuildingPreview
          geometryType={form.values.geometryType}
          vertices={form.values.vertices as Vertex[]}
        />
      </div>
    </ModalBase>
  )
}
