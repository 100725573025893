import * as yup from "yup"

export const latitudeValidator =  yup.number()
  .required("Påkrevd")
  .typeError("Må være et tall")
  .test("mustBeLatitudeCoordinate", "Må være breddegrad", value => {
    const numericValue = Number(value)
    return !isNaN(numericValue)
      && isFinite(numericValue)
      && Math.abs(numericValue) <= 90
  })

export const longitudeValidator = yup.number()
  .required("Påkrevd")
  .typeError("Må være et tall")
  .test("mustBeLongitudeCoordinate", "Må være lengdegrad", value => {
    const numericValue = Number(value)
    return !isNaN(numericValue)
      && isFinite(numericValue)
      && Math.abs(numericValue) <= 180
  })

export const altitudeValidator = yup.number()
  .required("Påkrevd")
  .typeError("Må være et tall")
  .min(0, "Må være positiv")

export const eastingValidator = yup.number()
  .required("Påkrevd")
  .typeError("Må være et tall")
  .test("mustBeEastingCoordinate", "Må være østkoordinat", value => {
    const numericValue = Number(value)
    return !isNaN(numericValue)
      && isFinite(numericValue)
      && 0 <= numericValue
      && numericValue < 1e6
  })
export const northingValidator = yup.number()
  .required("Påkrevd")
  .typeError("Må være et tall")
  .test("mustBeNorthingCoordinate", "Må være nordkoordinat", value => {
    const numericValue = Number(value)
    return !isNaN(numericValue)
      && isFinite(numericValue)
      && 0 < numericValue
      && numericValue < 1e7
  })
