import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"

export interface SicalcParameters {
  areaSelectionMode: AreaSelectionMode
  resolution: GridResolution | null
  altitude: number | null
  outputCoordinateSystem: CoordinateSystem
  outputCoordinateSystemZone: number | null
}

export enum AreaSelectionMode {
  everywhere = "everywhere",
  selectedArea = "selectedArea",
}

export enum GridResolution {
  G_01 = "g_01",
  G_03 = "g_03",
  G_09 = "g_09",
  G_27 = "g_27",
  G_81 = "g_81"
}
