import './areaSelectionModePicker.scoped.scss'
import { FormSectionHeading } from "components/common/headings/formSectionHeading/formSectionHeading"
import { AreaSelectionMode } from "services/sicalcApi/sharedEntities/sicalcParameters"
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import { useEffect, useState } from "react"

export interface AreaSelectionModePickerProps {
  value: AreaSelectionMode
  onChange: (newValue: AreaSelectionMode) => void
  onClickMarkCalculationArea: () => void
  calculationTaskHasCalculationArea: boolean
}

export const AreaSelectionModePicker = (props: AreaSelectionModePickerProps) => {
  const [ currentValue, setCurrentValue ] = useState<AreaSelectionMode>(props.value)

  useEffect(() => {
    setCurrentValue(props.value)
  }, [props.value])

  const isCurrentValue = (value: AreaSelectionMode) => {
    return value === currentValue
  }

  return (
    <div className="area-selection-type-toggle column">
      <span className="heading">
        <FormSectionHeading>Type støykartlegging</FormSectionHeading>
      </span>
      <div className="radio-buttons column">
        <label className="radio-button row align-center">
          <input
            type="radio"
            name="areaSelectionMode"
            value={AreaSelectionMode.everywhere}
            checked={isCurrentValue(AreaSelectionMode.everywhere)}
            onChange={e => props.onChange(e.target.value as AreaSelectionMode)}
          />
          <span>Total kartlegging av flyplass</span>
        </label>
        <div className="row space-between align-center">
          <label className="radio-button row align-center">
            <input
              type="radio"
              name="areaSelectionMode"
              value={AreaSelectionMode.selectedArea}
              checked={isCurrentValue(AreaSelectionMode.selectedArea)}
              onChange={e => props.onChange(e.target.value as AreaSelectionMode)}
            />
            <span>Egendefinert område</span>
          </label>

          { currentValue === AreaSelectionMode.selectedArea && !props.calculationTaskHasCalculationArea
            && (
              <ClearButton
                onClick={props.onClickMarkCalculationArea}
                text="Legg til"
                iconName={"plus"}
              />
            )
          }
          { currentValue === AreaSelectionMode.selectedArea && props.calculationTaskHasCalculationArea
            && (
              <ClearButton
                onClick={props.onClickMarkCalculationArea}
                text="Endre"
                iconName={"pencil"}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

