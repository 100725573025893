import papaParse from "papaparse"
import { CalculationPoint } from "components/calculationTask/calculationTaskSetupView/formDataModel"
import {
  UtmCalculationPoint
} from "components/calculationTask/importUtmCalculationPointsForm/utmCalculationPointFormDataModel"

const isLatitude = (value: unknown) => {
  const numericValue = Number(value)
  return !isNaN(numericValue) && isFinite(numericValue) && Math.abs(numericValue) < 90
}

const isLongitude = (value: unknown) => {
  const numericValue = Number(value)
  return !isNaN(numericValue) && isFinite(numericValue) && Math.abs(numericValue) < 180
}
const isEasting = (value: unknown) => {
  const numericValue = Number(value)
  return !isNaN(numericValue)
    && isFinite(numericValue)
    && 0 <= numericValue
    && numericValue < 1e6
}
const isNorthing = (value: unknown) => {
  const numericValue = Number(value)
  return !isNaN(numericValue)
    && isFinite(numericValue)
    && 0 < numericValue
    && numericValue < 1e7
}

const parseAltitude = (value: string) => {
  const numericValue = Number(value.replace(',', '.'))
  if (numericValue === 1.5 || numericValue === 4) {
    return numericValue
  }

  return undefined
}

export const parseGeographicCoordinates = (clipboardContent: string): CalculationPoint[] | null => {
  const result = papaParse.parse<string[]>(clipboardContent, {
    skipEmptyLines: true,
  })

  // Check if there are multiple values at all
  if (result.data.length === 0) {
    return null
  }
  if (result.data.length === 1
    && result.data[0].length < 2
  ) {
    return null
  }

  // Check if it's just two coordinates
  const firstItem = result.data[0]
  if (firstItem.length === 2
    && isLatitude(firstItem[0])
    && isLongitude(firstItem[1])
  ) {
    return result.data.map(line => ({
      latitude: Number(line[0]),
      longitude: Number(line[1]),
    }))
  }

  // Check if it's two coordinates followed by a height
  if (firstItem.length === 3
    && isLatitude(firstItem[0])
    && isLongitude(firstItem[1])
  ) {
    return result.data.map(line => ({
      latitude: Number(line[0]),
      longitude: Number(line[1]),
      altitude: parseAltitude(line[2]),
    }))
  }

  // Check if it's a name followed by two coordinates
  if (firstItem.length === 3
    && isLatitude(firstItem[1])
    && isLongitude(firstItem[2])
  ) {
    return result.data.map(line => ({
      name: line[0],
      latitude: Number(line[1]),
      longitude: Number(line[2]),
    }))
  }

  // Check if it's [name, latitude, longitude, altitude]]
  if (firstItem.length === 4
    && isLatitude(firstItem[1])
    && isLongitude(firstItem[2])
  ) {
    return result.data.map(line => ({
      name: line[0],
      latitude: Number(line[1]),
      longitude: Number(line[2]),
      altitude: parseAltitude(line[3]),
    }))
  }

  // The data is something else, so do nothing
  return null
}

export const parseUtmCoordinates = (clipboardContent: string): UtmCalculationPoint[] | null => {
  const result = papaParse.parse<string[]>(clipboardContent, {
    skipEmptyLines: true,
  })

  // Check if there are multiple values at all
  if (result.data.length === 0) {
    return null
  }
  if (result.data.length === 1
    && result.data[0].length < 2
  ) {
    return null
  }

  // Check if it's just two coordinates
  const firstItem = result.data[0]
  if (firstItem.length === 2
    && isEasting(firstItem[0])
    && isNorthing(firstItem[1])
  ) {
    return result.data.map(line => ({
      easting: Number(line[0]),
      northing: Number(line[1]),
    }))
  }

  // Check if it's two coordinates followed by a height
  if (firstItem.length === 3
    && isEasting(firstItem[0])
    && isNorthing(firstItem[1])
  ) {
    return result.data.map(line => ({
      easting: Number(line[0]),
      northing: Number(line[1]),
      altitude: parseAltitude(line[2]),
    }))
  }

  // Check if it's a name followed by two coordinates
  if (firstItem.length === 3
    && isEasting(firstItem[1])
    && isNorthing(firstItem[2])
  ) {
    return result.data.map(line => ({
      name: line[0],
      easting: Number(line[1]),
      northing: Number(line[2]),
    }))
  }

  // Check if it's [name, latitude, longitude, altitude]]
  if (firstItem.length === 4
    && isEasting(firstItem[1])
    && isNorthing(firstItem[2])
  ) {
    return result.data.map(line => ({
      name: line[0],
      easting: Number(line[1]),
      northing: Number(line[2]),
      altitude: parseAltitude(line[3]),
    }))
  }

  // The data is something else, so do nothing
  return null
}
